import { ComponentStyleConfig } from '@chakra-ui/react';

export const Avatar: ComponentStyleConfig = {
   parts: ['container', 'label'],
   sizes: {
      md: {
         container: {
            height: 9,
            width: 9,
         },
         label: {
            fontSize: '90%',
         },
      },
   },
};
