import { ThemeOverride } from '@chakra-ui/react';
import {
   Alert,
   Avatar,
   Badge,
   Button,
   IconBadge,
   Input,
   Link,
   Menu,
   Modal,
   Pagination,
   Select,
   Tabs,
   Tooltip,
   ProductPrice,
} from './components';
import {
   breakpoints,
   colors,
   radii,
   shadows,
   sizes,
   space,
   typography,
   zIndices,
} from './foundations';
import { styles } from './styles';

export const theme: ThemeOverride = {
   breakpoints,
   colors,
   radii,
   shadows,
   sizes,
   styles,
   space,
   ...typography,
   zIndices,
   components: {
      Alert,
      Avatar,
      Badge,
      Button,
      IconBadge,
      Input,
      Link,
      Menu,
      Modal,
      Pagination,
      ProductPrice,
      Select,
      Tabs,
      Tooltip,
   },
};

export * from './components';
export * from './foundations';
