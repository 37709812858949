import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
   parts: ['addon', 'field', 'element'],
   baseStyle: {
      field: {
         font: 'inherit',
      },
   },
   variants: {
      outline: {
         field: {
            _disabled: {
               bgColor: 'gray.100',
            },
            _invalid: {
               bgColor: 'red.50',
            },
         },
      },
   },
   sizes: {
      sm: {
         field: {
            borderRadius: 'md',
            minHeight: '28px',
         },
      },
      md: {
         field: {
            minHeight: '36px',
         },
      },
   },
};
