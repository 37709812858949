// We want to funnel all ChakraProvider instances through this component
// so that we can control the Provider props from a single location.

/* eslint-disable react/forbid-elements */

import { ChakraProvider, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { ReactNode, createContext, useContext } from 'react';
import { theme } from '@ifixit/carpenter-theme';

interface ChakraDefaultProviderProps {
   children: ReactNode;
   theme?: ThemeConfig | false;
   [key: string]: any;
}

const carpenterTheme = extendTheme(theme);
const ChakraInitContext = createContext(false);

export function ChakraDefaultProvider({ children, ...props }: ChakraDefaultProviderProps) {
   const isInitialized = useContext(ChakraInitContext);

   return (
      <ChakraInitContext.Provider value={true}>
         <ChakraProvider
            {...props}
            theme={props.theme || carpenterTheme}
            resetCSS={false}
            disableGlobalStyle={isInitialized}
         >
            {children}
         </ChakraProvider>
      </ChakraInitContext.Provider>
   );
}
