import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentStyleConfig = {
   parts: ['tablist', 'tab'],
   variants: {
      line: {
         tablist: {
            borderColor: 'gray.300',
         },
         tab: {
            borderColor: 'gray.300',
            borderRadius: '0',
            color: 'gray.500',
            fontWeight: 'bold',
         },
      },
   },
};
