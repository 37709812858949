import { ThemeOverride } from '@chakra-ui/react';

export const styles: ThemeOverride['styles'] = {
   global: {
      ':root[data-theme=light], [data-theme][data-theme=light]': {
         '--chakra-colors-chakra-border-color': 'var(--chakra-colors-gray-300, #d1d5db)',
      },
      ':where([class^="chakra-"], [class^="css-"])': {
         borderWidth: '0',
         borderStyle: 'solid',
         borderColor: 'var(--chakra-colors-gray-300, #d1d5db)',
      },
   },
};
